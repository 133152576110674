import React from 'react'
import flask from '../assets/img/FLASK.png'

export const Step2 = ({ setForm, formData, navigation }) => {

    
    const { ca } = formData;
    const { next, previous } = navigation;
   
    return (
        <div className="padded">
            <h2><img src={flask} alt="icon" width="50"/> Valores analíticos</h2>
            <p>¿Cree que su paciente tiene o podría tener riesgo de hipercalcemia?</p>
            
            
            <div className="row row-cols-2 py-3">
                <div className="col">
                    <input 
                        id="ca1"
                        className="form-control text-center siono" 
                        type="radio"
                        name="ca"
                        value="si" 
                        checked={ ( ca === 'si' ? 'checked' : '') }
                        onChange={setForm}
                    />
                    <label htmlFor="ca1">
                        SI
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="ca2"
                        className="form-control text-center siono" 
                        type="radio"
                        name="ca"
                        value="no" 
                        checked={ ( ca === 'no' ? 'checked' : '') }
                        onChange={setForm}
                    />
                    <label htmlFor="ca2">
                        NO
                    </label>
                    
                </div>
            </div>
            { ca === 'si' && 
            <>
            <p class="alert alert-warning small">Según las guías KDIGO, se deben restringir los captores de fósforo de base cálcica en pacientes con riesgo de hipercalcemia. Más información: <a href="https://kdigo.org/guidelines/ckd-mbd/" rel="noreferrer" target="_blank">aquí</a></p>
            </>
        
            }
            
            <div className="btn__container">
            <button className="btn-back" onClick={previous}> {'<'} </button>  <button className="btn-main" onClick={next}>SIGUIENTE</button>
            </div>
        </div>
    )
}
