import React from 'react'
import { useForm, useStep } from "react-hooks-helper";

import {Intro} from './Intro';
import {Step1} from './Step1';
import {Step2} from './Step2';
import {Aviso} from './Aviso';
import {Step9} from './Step9';
import {Result} from './Result';



const steps = [
  { id: "intro" },
  { id: "step1" },
  { id: "step2" },
  { id: "step9" },
  { id: "result" },
  { id: "aviso" }
];
const defaultData = {
  pth: 'no',
  ca: 'no',
  inmunosupresores: false,
  iecas: false,
  warfarina: false,
  digoxina: false,
  tiazidas: false,
  omeprazol: false,
  antagonistas: false,
  metformina: false,
  estatinas: false,
  penicilinas: false,
  quinolonas: false,
  k2: false,
  metropolol: false,
  antiagregantes: false, 
  cinacalcet: false, 
  vd: false, 
  ovl: false, 
  cefalosporinas: false, 
  tetradoxi: false, 
  antifungicos: false, 
  sales: false, 
  anticolinergicos: false, 
  levotiroxina: false, 
  bifosfonatos: false
};

export const MasterForm = ({ images }) => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const props = { formData, setForm, navigation };
  
  switch (id) {
    case "intro":
      return <Intro {...props} />;
    case "step1":
      return <Step1 {...props} />;
    case "step2":
      return <Step2 {...props} />;
    case "aviso":
      return <Aviso {...props} />;
    case "step9":
      return <Step9 {...props} />;
    case "result":
      return <Result {...props} />;
    default:
      return <Intro {...props} />;
  }
};

