import React from 'react'
import Logo1 from '../assets/img/vifor1.png'
import Logo2 from '../assets/img/vifor4.png'

export const Intro = ({ navigation }) => {
    
    const { next } = navigation;

    return (
        <div className="padded intro">
            <p><span className="text-blue">La elección del captor de fósforo</span> más recomendable para cada paciente a veces <span className="text-blue">puede resultar una tarea más complicada de lo esperado</span>, ya que existe una gran variedad de factores que pueden influir en esta decisión.<br /><span className="text-blue">Esta webapp</span> es una herramienta práctica y fácil de utilizar que <span className="text-blue">se ha diseñado con el objetivo de facilitar la toma de esta decisión</span> en la práctica clínica.</p>
            <p><span className="text-blue">El Dr. Emilio Sánchez Álvarez</span>, Jefe de Servicio de Nefrología del Hospital Universitario de Cabueñes, Gijón ha sido el <span className="text-blue">coordinador clínico en el desarrollo de esta aplicación web.</span></p>
            <br/>
            <p className="text-muted">Con la colaboración y patrocinio de:</p>
            <div className="row row-cols-1 row-cols-lg-2 logocontainer">
                <div className="col"><img src={Logo1} alt="vifor" width="90%" /></div>
                <div className="col"><img src={Logo2} alt="vifor" width="90%" /></div>
            </div>
            <div className="btn__container">
                <button className="btn-main" onClick={next}>EMPEZAR</button>
            </div>
        </div>
    )
}
