import React from 'react'
import {MasterForm} from './MasterForm';
import {Header} from './Header';
export const Home = () => {
    return (
        <div>
            <div className="main">
                <div className="header">
                    <Header />
                </div>
            
                <div className="master text-center">
                    <div className="intro">
                        <div className="container">
                            <MasterForm />
                        </div>
                    </div>
                        
                </div>
            </div>
        </div>
    )
}
