import React from 'react';
import resultimg from '../assets/img/result.png'
import ResultadoQuelante from './ResultadoQuelante'
    let PREC = true;
    let SEVE = 0;
    let LANT = 0;
    let OXIH = 0;
    let AFIRMA = true;


export const Result = ( {formData } ) => {

    
    const finish = () => {
        window.location.reload();
    }
    const { pth, ca, inmunosupresores, iecas, warfarina, digoxina, tiazidas,  omeprazol, antagonistas, metformina, estatinas, penicilinas, quinolonas, k2, metropolol, antiagregantes, cinacalcet, vd, ovl, cefalosporinas, tetradoxi, antifungicos, sales, anticolinergicos, levotiroxina, bifosfonatos } = formData;

    const PTH = pth;
    const CA = ca;

    const INMUNOSUPRESORES = inmunosupresores;
    const IECAS = iecas;
    const WARFARINA = warfarina;
    const DIGOXINA = digoxina;
    const TIAZIDAS = tiazidas;
    const OMEPRAZOL = omeprazol;
    const ANTAGONISTAS = antagonistas;
    const METFORMINA = metformina;
    const ESTATINAS = estatinas;
    const PENICILINAS = penicilinas;
    const QUINOLONAS = quinolonas;
    const METROPOLOL = metropolol;
    const ANTIAGREGANTES = antiagregantes;
    const CINACALCET = cinacalcet;
    const VD = vd;
    const OVL = ovl;
    const CEFALOSPORINAS = cefalosporinas;
    const TETRADOXI = tetradoxi;
    const ANTIFUNGICOS = antifungicos;
    const SALES = sales;
    const ANTICOLINERGICOS = anticolinergicos;
    const LEVOTIROXINA = levotiroxina;
    const BIFOSFONATOS = bifosfonatos;
    const K2 = k2;
    let BOTH = false;
    let CALCICO = false;
    let NOCALCICO = false;
    let RCOL = 'row-cols-1';
    let COL = 'row-cols-3';
    

    
    if ( PTH === 'no' ) {
        BOTH = true;
        CALCICO = true;
        NOCALCICO = true;
    }
    if ( PTH === 'si' || CA === 'si') {
        BOTH = false;
        CALCICO = false;
        NOCALCICO = true;
    }
    
    if (BOTH) {
        COL = 'row-cols-6 peque';
        RCOL = 'row-cols-2';
    }
    
    return (
        <div>
            
            <h2 className="mt-3 mb-5"><img src={resultimg} className="result__image" alt="resultados" width="80" /> Resultados</h2>
            
            
            
                {
                INMUNOSUPRESORES && NOCALCICO &&
                    <>
                        {addPoints(-100,1,2)}
                    </>
                }
                {
                 IECAS && NOCALCICO &&
                    <>  
                        {addPoints(2,2,2)}
                    </>
                }

                {
                WARFARINA && NOCALCICO &&   
                <>        
                        {addPoints(2,2,2)}
                </>
                }
                {
                METFORMINA && NOCALCICO &&
                        <>
                        {addPoints(1,1,2)}
                        
                        </>
                }

                {
                ESTATINAS && NOCALCICO &&
                        <>
                        {addPoints(1,1,2)}
                        
                        </>
                    
                }
                {
                OMEPRAZOL && NOCALCICO &&
                        <>
                        {addPoints(-100,1,2)}
                        
                        </>

                }
                {
                DIGOXINA && CALCICO && 
                        <>
                        {addProhib()}
                        
                        </>
                }
                {
                DIGOXINA && NOCALCICO &&
                        <>
                        {addPoints(2,2,2)}
                        
                        </>
                }
                {
                ANTAGONISTAS && CALCICO && 
                        <>
                        {addProhib()}
                        
                        </>
                }
                {
                ANTAGONISTAS && NOCALCICO &&
                        <>
                        {addPoints(1,1,2)}
                        
                        </>

                }
                {
                TIAZIDAS && CALCICO && 
                        <>
                        {addProhib()}
                        
                        </>
                }
                {
                TIAZIDAS && NOCALCICO &&
                        <>
                        {addPoints(-100,-100,2)}
                        
                        </>
                }
                {
                QUINOLONAS && CALCICO && 
                        <>
                        {addProhib()}
                        
                        </>
                }
                {
                QUINOLONAS && NOCALCICO &&
                        <>
                        {addPoints(-100,-100,2)}
                        </>
                    
                }
                {
                PENICILINAS && CALCICO && 
                        <>
                        {addProhib()}
                        
                        </>
                }
                {
                PENICILINAS && NOCALCICO &&
                        <>
                        {addPoints(1,1,1)}
                        </>
                }
                
                {
                METROPOLOL && NOCALCICO &&
                        <>
                        {addPoints(2,2,2)}                    
                        </>
                }
                {
                ANTIAGREGANTES && NOCALCICO &&
                        <>
                        {addPoints(1,1,2)}
                        </>
                }
                {
                CINACALCET && NOCALCICO &&
                        <>
                        {addPoints(1,1,2)}
                        </>
                }
                {
                VD && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                VD && NOCALCICO &&
                        <>
                        {addPoints(-100,2,2)}
                        </>
                }
                {
                K2 && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                
                {
                K2 && NOCALCICO &&
                        <>
                        {addPoints(2,-100,2)}
                        </>
                }
                {
                OVL && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                OVL && NOCALCICO &&
                        <>
                        {addPoints(-100,2,2)}
                        </>
                }
                {
                CEFALOSPORINAS && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                CEFALOSPORINAS && NOCALCICO &&
                        <>
                        {addPoints(1,1,2)}
                        </>
                }
                {
                TETRADOXI && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                TETRADOXI && NOCALCICO &&
                        <>
                        {addPoints(1,-100,-100)}
                        </>
                }
                {
                TETRADOXI && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                ANTIFUNGICOS && NOCALCICO &&
                        <>
                        {addPoints(1,-100,1)}
                        </>
                }
                {
                SALES && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                SALES && NOCALCICO &&
                        <>
                        {addPoints(1,1,2)}
                        </>
                }
                {
                ANTICOLINERGICOS && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                ANTICOLINERGICOS && NOCALCICO &&
                        <>
                        {addPoints(1,1,1)}
                        </>
                }
                {
                LEVOTIROXINA && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                LEVOTIROXINA && NOCALCICO &&
                        <>
                        {addPoints(-100,-100,-100)}
                        </>
                }
                {
                BIFOSFONATOS && CALCICO && 
                        <>
                        {addProhib()}
                        </>
                }
                {
                BIFOSFONATOS && NOCALCICO &&
                        <>
                        {addPoints(-100,-100,-100)}
                        </>
                }
            {
                SEVE < 0 && LANT < 0 &&  OXIH < 0 &&
                    closeAfirma()
            }
            
            
            {!INMUNOSUPRESORES && !IECAS && !WARFARINA && !DIGOXINA && !TIAZIDAS && !OMEPRAZOL && !ANTAGONISTAS && !METFORMINA && !ESTATINAS && !PENICILINAS && !QUINOLONAS && !K2 && !METROPOLOL && !ANTIAGREGANTES && !CINACALCET && !VD && !OVL && !CEFALOSPORINAS && !TETRADOXI && !ANTIFUNGICOS && !SALES && !ANTICOLINERGICOS && !LEVOTIROXINA && !BIFOSFONATOS ? '' :
            <>
            {
                NOCALCICO && AFIRMA &&
                <p class="alert alert-info small py-0 d-none">El captor de fósforo que tiene menos interacción con los tratamientos concomitantes de su paciente es <b>{Recom()}</b></p>
            }
            {
                NOCALCICO && !AFIRMA && PREC &&
                <p class="alert alert-danger small py-0 d-none">Alguno de los tratamientos concomitantes interacciona con todos los captores de fosforo disponibles.</p>
            } 
            {
                NOCALCICO && !AFIRMA && !PREC &&
                <p class="alert alert-danger small py-0 d-none">Alguno de los tratamientos concomitantes interacciona con todos los quelantes de fosforo disponibles</p>
            } 
            </>
            }
            
            {!INMUNOSUPRESORES && !IECAS && !WARFARINA && !DIGOXINA && !TIAZIDAS && !OMEPRAZOL && !ANTAGONISTAS && !METFORMINA && !ESTATINAS && !PENICILINAS && !QUINOLONAS && !K2 && !METROPOLOL && !ANTIAGREGANTES && !CINACALCET && !VD && !OVL && !CEFALOSPORINAS && !TETRADOXI && !ANTIFUNGICOS && !SALES && !ANTICOLINERGICOS && !LEVOTIROXINA && !BIFOSFONATOS ? '' :
                <>
                <p className="mini text-dark mb-0 d-none">Para más información consulte la siguiente tabla:</p>
                <div className={'row '+ RCOL}>
                { CALCICO &&
                    
                    <div className="col result__type">BASADOS EN CALCIO</div>
                }
                { NOCALCICO &&
                    
                    <div className="col result__type">NO CÁLCICOS</div>
                }
                
                </div>
                </>
            }   
                {
                INMUNOSUPRESORES &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        INMUNOSUPRESORES
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante color1="red" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                }
                {
                 IECAS &&
                 <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        IECAS
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="green" color2="green" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                }

                {
                WARFARINA &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        WARFARINA
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante color1="green" color2="green" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                METFORMINA &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        METFORMINA / PIOGLITAZONA
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante cormobilidad="METFORMINA"  color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante cormobilidad="METFORMINA"  color1="yellow" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                }
                {
                ESTATINAS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        ESTATINAS
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante cormobilidad="ESTATINAS"  color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante cormobilidad="ESTATINAS"  color1="yellow" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                }
                {
                OMEPRAZOL &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        OMEPRAZOL
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante cormobilidad="OMEPRAZOL"  color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante cormobilidad="OMEPRAZOL"  color1="red" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                DIGOXINA &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        DIGOXINA
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante cormobilidad="DIGOXINA"  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante cormobilidad="DIGOXINA"  color1="green" color2="green" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                ANTAGONISTAS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        ANTAGONISTAS CANALES CALCIO
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante cormobilidad="ANTAGONISTAS"  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante cormobilidad="ANTAGONISTAS"  color1="yellow" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                TIAZIDAS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        TIAZIDAS
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante cormobilidad="TIAZIDAS"  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante cormobilidad="TIAZIDAS"  color1="white" color2="white" color3="green" quelante1="Sevelámero (No provoca hipercalcemia)*" quelante2="Carbonato de lantano (No provoca hipercalcemia)*" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                QUINOLONAS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        QUINOLONAS
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante cormobilidad="QUINOLONAS"  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante cormobilidad="QUINOLONAS"  color1="red" color2="red" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                PENICILINAS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        PENICILINAS
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="yellow" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                
                {
                METROPOLOL &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        METROPOLOL
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="green" color2="green" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                ANTIAGREGANTES &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        AGENTES ANTIAGREGANTES
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                CINACALCET &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        CINACALCET
                    </div>
                    { 
                        CALCICO && 
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="yellow" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                VD &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        VITAMINA D
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="green" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                K2 &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        VITAMINA K2
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                OVL &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        OTRAS VITAMINAS LIPOSOLUBLES
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="green" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                CEFALOSPORINAS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        CEFALOSPORINAS
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                TETRADOXI &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        TETRACICLINAS / DOXICICLINA
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="red" color3="red" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                ANTIFUNGICOS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        ANTIFÚNGICOS
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="red" color3="yellow" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                SALES &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        SALES DE HIERRO
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="green" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                ANTICOLINERGICOS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        AGENTES ANTICOLINÉRGICOS
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="yellow" color2="yellow" color3="yellow" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                LEVOTIROXINA &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        LEVOTIROXINA
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
                {
                BIFOSFONATOS &&
                <div className={'row ' + COL + ' mb-2 resultados'}>
                    <div className="col-12 titulo">
                        BIFOSFONATOS
                    </div>
                    { 
                        CALCICO && 
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Carbonato cálcico" quelante2="Acetato de Calcio" quelante3="Acetato de calcio/ Carbonato de Mg" />
                        </>
                    }
                    {
                        NOCALCICO &&
                        <>
                        
                        <ResultadoQuelante  color1="red" color2="red" color3="red" quelante1="Sevelámero" quelante2="Carbonato de lantano" quelante3="Oxihidróxido sucroférrico" />
                        </>
                    }

                </div>
                
                }
            {!INMUNOSUPRESORES && !IECAS && !WARFARINA && !DIGOXINA && !TIAZIDAS && !OMEPRAZOL && !ANTAGONISTAS && !METFORMINA && !ESTATINAS && !PENICILINAS && !QUINOLONAS && !K2 && !METROPOLOL && !ANTIAGREGANTES && !CINACALCET && !VD && !OVL && !CEFALOSPORINAS && !TETRADOXI && !ANTIFUNGICOS && !SALES && !ANTICOLINERGICOS && !LEVOTIROXINA && !BIFOSFONATOS ? '' :
                <div className="leyenda my-3">
                <div className="row row-cols-3">
                    <div className="col">
                        <div className="spot rojo">
                            <span></span>
                        </div>
                        <p className="mb-0">REDUCCIÓN DE</p>
                        <p className="mb-0">ABSORCIÓN / EFICIENCIA</p>
                    </div>
                    <div className="col">
                        <div className="spot amarillo">
                            <span></span>
                        </div>
                        <p className="mb-0">INTERACCIÓN</p>
                        <p className="mb-0">NO ESPECIFICADA</p>
                    </div>
                    <div className="col">
                        <div className="spot verde">
                            <span></span>
                        </div>
                        <p className="mb-0">SIN</p>
                        <p className="mb-0">INTERACCIÓN</p>
                    </div>
                </div>
            </div>
            }   
            
            
            
            {!INMUNOSUPRESORES && !IECAS && !WARFARINA && !DIGOXINA && !TIAZIDAS && !OMEPRAZOL && !ANTAGONISTAS && !METFORMINA && !ESTATINAS && !PENICILINAS && !QUINOLONAS && !K2 && !METROPOLOL && !ANTIAGREGANTES && !CINACALCET && !VD && !OVL && !CEFALOSPORINAS && !TETRADOXI && !ANTIFUNGICOS && !SALES && !ANTICOLINERGICOS && !LEVOTIROXINA && !BIFOSFONATOS && !CALCICO && <p className="alert alert-success">El paciente puede utilizar cualquiera de los captores de fósforo de base no cálcica comercializados</p>   }
            {!INMUNOSUPRESORES && !IECAS && !WARFARINA && !DIGOXINA && !TIAZIDAS && !OMEPRAZOL && !ANTAGONISTAS && !METFORMINA && !ESTATINAS && !PENICILINAS && !QUINOLONAS && !K2 && !METROPOLOL && !ANTIAGREGANTES && !CINACALCET && !VD && !OVL && !CEFALOSPORINAS && !TETRADOXI && !ANTIFUNGICOS && !SALES && !ANTICOLINERGICOS && !LEVOTIROXINA && !BIFOSFONATOS && CALCICO && <p className="alert alert-success">La elección del captor de fósforo debe hacerse según el criterio del profesional sanitario</p>   }
            {!INMUNOSUPRESORES && !IECAS && !WARFARINA && !DIGOXINA && !TIAZIDAS && !OMEPRAZOL && !ANTAGONISTAS && !METFORMINA && !ESTATINAS && !PENICILINAS && !QUINOLONAS && !K2 && !METROPOLOL && !ANTIAGREGANTES && !CINACALCET && !VD && !OVL && !CEFALOSPORINAS && !TETRADOXI && !ANTIFUNGICOS && !SALES && !ANTICOLINERGICOS && !LEVOTIROXINA && !BIFOSFONATOS ? '' :
                <>
                <p className="reference mb-0"><small><sup>1</sup> Bover J, González-Navarro JF, Arenas MD, et al. Pharmacological interactions of phosphate binders. Nefrología (Engl Ed). 2018;38(6):573-578</small></p>    
                <p className="reference mb-0"><small><sup>2</sup> Bover J, González-Navarro JF, daSilva I. New information on phosphate binder interactions with vitamin K. Nefrología (Engl Ed). 2020;40(3):369-370</small></p>    
                </>
            }
            {TIAZIDAS &&
                <p className="advisory">* Ni sevelámero ni carbonato de lantano han sido estudiados con diuréticos</p>
            }
            <p className="advisory alert alert-info p-0 mt-2">
                Para consultar la ficha técnica de oxihidróxido sucroférrico haga click <a href="https://cima.aemps.es/cima/pdfs/ft/114943001/FT_114943001.pdf" target="_blank" rel="noreferrer">aquí</a><br />
                Para consultar cualquier otra ficha técnica diríjase <a href="https://cima.aemps.es/" target="_blank" rel="noreferrer">aquí</a></p>
            {!CALCICO &&
                <p className="advisory alert alert-warning p-0 mt-2">Según las guías KDIGO, se deben restringir los captores de fósforo de base cálcica en pacientes con riesgo de hipercalcemia. Más información: <a href="https://kdigo.org/guidelines/ckd-mbd/" rel="noreferrer" target="_blank">aquí</a></p>

            }
            
            <br/>
            <br/>

            
            <div className="btn__container">
            <button className="btn-main" onClick={finish}>FINALIZAR</button>
            </div>
            
        </div>
        
    )
}
function closeAfirma(){
    AFIRMA = false;
}

function addProhib() {
    PREC = false;
}
function addPoints(seve,lant,oxih) {
    SEVE = (SEVE + seve);
    LANT = (LANT + lant);
    OXIH = (OXIH + oxih);
}
function Recom(){
    
        if (SEVE > 0 && SEVE > LANT && SEVE > OXIH) {
            return ('sevelámero');
        } else if (LANT > 0 && LANT > SEVE && LANT > OXIH) {
            return ('carbonato de Lantano');
        } else if (OXIH > 0 && OXIH > SEVE && OXIH > LANT) {
            return ('oxihidróxido sucroférrico');
        } else if (OXIH > 0 && OXIH === SEVE && OXIH === LANT) {
            return ('oxihidróxido sucroférrico, sevelámero o carbonato de lantano');
        } else if (OXIH > 0 && OXIH === SEVE && OXIH > LANT ) {
            return ('oxihidróxido sucroférrico o sevelámero');
        } else if (OXIH > 0 && OXIH === LANT && OXIH > SEVE ) {
            return ('oxihidróxido sucroférrico o carbonato de lantano');
        } else if (SEVE > 0 && SEVE === LANT && SEVE > OXIH ) {
            return ('seveámero o carbonato de lantano');
        } else {
            AFIRMA = false;
            return ('captor no cálcico') ;
        }
    
  
}