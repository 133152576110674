import React from 'react'


export const Aviso = ({ navigation }) => {
    

    return (
        <div className="padded">
            <p>Según las guías KDIGO, los pacientes con niveles elevados de calcio no deben recibir quelantes de fósforo de base cálcica. Más información:</p>
            <p><a href="https://kdigo.org/wp-content/uploads/2017/02/2017-KDIGO-CKD-MBD-GL-Update.pdf" target="_blank" rel="noreferrer">https://kdigo.org/</a></p>
            <div className="btn__container">
                <button className="btn-main" onClick={()=> navigation.go(2)}>SIGUIENTE</button>
            </div>
        </div>
    )
}
