import React from 'react'
import flask from '../assets/img/FLASK.png'


export const Step1 = ({ setForm, formData, navigation }) => {

    const { pth } = formData;
    const { next, previous } = navigation;
    
    
    
    return (
        <div className="padded">
            <h2><img src={flask} alt="icon" width="50"/> Valores analíticos</h2>
            <p>¿Su paciente tiene un valor de PTH menor o igual a 120 pg/mL? (es decir, menos del doble del valor alto del rango de la normalidad)*</p>
            <p><small>* Guías KDIGO en <a href="https://kdigo.org/guidelines/ckd-mbd/" target="_blank" rel="noreferrer">CKD-MBD</a></small></p>
            <div className="row row-cols-2 py-3">
                <div className="col">
                    <input 
                        id="pth1"
                        className="form-control text-center siono" 
                        type="radio"
                        name="pth"
                        value="si" 
                        checked={ ( pth === 'si' ? 'checked' : '') }
                        onChange={setForm}
                    />
                    <label htmlFor="pth1">
                        SI
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="pth2"
                        className="form-control text-center siono" 
                        type="radio"
                        name="pth"
                        value="no" 
                        checked={ ( pth === 'no' ? 'checked' : '') }
                        onChange={setForm}
                    />
                    <label htmlFor="pth2">
                        NO
                    </label>
                    
                </div>
            </div>
            
            <div className="btn__container">
                <button className="btn-back" onClick={previous}> {'<'} </button>  <button className="btn-main" onClick={next}>SIGUIENTE</button>
            </div>
        </div>
    )
}
