import React from 'react'
import med from '../assets/img/med.png'


export const Step9 = ({ setForm, formData, navigation }) => {
    const { inmunosupresores, iecas, warfarina, digoxina, tiazidas,  omeprazol, antagonistas, metformina, estatinas, penicilinas, quinolonas, k2, metropolol, antiagregantes, cinacalcet, vd, ovl, cefalosporinas, tetradoxi, antifungicos, sales, anticolinergicos, levotiroxina, bifosfonatos } = formData;
    
    const { next, previous } = navigation;
    return (
        <div className="padded largepad">
            <h2><img src={med} alt="icon" width="50"/> Tratamientos concomitantes</h2>
            <p>Indique los principales <b>tratamientos concomitantes</b> de su paciente:</p>
            
            
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 py-3">
                <div className="col">
                    <input 
                        id="trat1"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="inmunosupresores"
                        value={inmunosupresores} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat1">
                        INMUNOSUPRESORES
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat2"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="iecas"
                        value={iecas} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat2">
                        IECAs
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat3"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="warfarina"
                        value={warfarina} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat3">
                        WARFARINA
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat4"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="digoxina"
                        value={digoxina} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat4">
                        DIGOXINA
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat5"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="tiazidas"
                        value={tiazidas} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat5">
                        TIAZIDAS
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat6"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="omeprazol"
                        value={omeprazol} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat6">
                        OMEPRAZOL
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat7"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="antagonistas"
                        value={antagonistas} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat7">
                         ANTAGONISTAS DE CANALES DE CALCIO
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat8"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="metformina"
                        value={metformina} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat8">
                        METFORMINA / PIOGLITAZONA
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat9"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="estatinas"
                        value={estatinas} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat9">
                        ESTATINAS
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat10"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="penicilinas"
                        value={penicilinas} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat10">
                        PENICILINAS
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat11"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="quinolonas"
                        value={quinolonas} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat11">
                        QUINOLONAS
                    </label>
                </div>
                
                <div className="col">
                    <input 
                        id="trat13"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="metropolol"
                        value={metropolol} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat13">
                        METROPOLOL
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat14"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="antiagregantes"
                        value={antiagregantes} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat14">
                        AGENTES ANTIAGREGANTES
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat15"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="cinacalcet"
                        value={cinacalcet} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat15">
                        CINACALCET
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat16"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="vd"
                        value={vd} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat16">
                        VITAMINA D
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat12"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="k2"
                        value={k2} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat12">
                        VITAMINA K2
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat17"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="ovl"
                        value={ovl} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat17">
                        OTRAS VITAMINAS LIPOSOLUBLES
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat18"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="cefalosporinas"
                        value={cefalosporinas} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat18">
                        CEFALOSPORINAS
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat19"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="tetradoxi"
                        value={tetradoxi} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat19">
                        TETRACICLINAS / DOXICICLINA
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat20"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="antifungicos"
                        value={antifungicos} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat20">
                        ANTIFÚNGICOS
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat21"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="sales"
                        value={sales} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat21">
                        SALES DE HIERRO
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat22"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="anticolinergicos"
                        value={anticolinergicos} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat22">
                        AGENTES ANTICOLINÉRGICOS
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat23"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="levotiroxina"
                        value={levotiroxina} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat23">
                        LEVOTIROXINA
                    </label>
                </div>
                <div className="col">
                    <input 
                        id="trat24"
                        className="form-control text-center siono tratam" 
                        type="checkbox"
                        name="bifosfonatos"
                        value={bifosfonatos} 
                        onChange={setForm}
                    />
                    <label htmlFor="trat24">
                        BIFOSFONATOS
                    </label>
                </div>
                
                
            </div>
            
            <div className="btn__container">
                <button className="btn-back" onClick={previous}> {'<'} </button>  <button className="btn-main" onClick={next}>SIGUIENTE</button>
            </div>
        </div>
    )
}
