function ResultadoQuelante(props){
    return (
        
            <>
                <div className={ 'col ' + props.color1}>
                    {props.quelante1}
                </div>
                <div className={ 'col ' + props.color2}>
                    {props.quelante2}
                </div>
                <div className={ 'col ' + props.color3}>
                    {props.quelante3}
                </div>

            </>
        
    )
}
export default ResultadoQuelante